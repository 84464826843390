import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { HomePage, CopyrightFooter } from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import TernyxPage from "./pages/TernyxPage";
import NavBar from "./components/NavBar";

function ExternalRedirect({ to }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Only redirect if the "redirected" flag is not present.
    if (!params.get("redirected")) {
      // Update the URL with a flag; replace the current history entry.
      navigate(`${location.pathname}?redirected=true`, { replace: true });
      // Navigate to the external docs page; this adds a new history entry.
      window.location.assign(to);
    }
  }, [to, location, navigate]);

  return (
    <div>
      <p>
        Redirecting to the documentation. If you are not redirected automatically,{" "}
        <a href={to}>click here</a>.
      </p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="app">
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route 
            path="/docs" 
            element={<ExternalRedirect to="https://exla.mintlify.app/quickstart" />} 
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/ternyx" element={<TernyxPage />} />
        </Routes>
        <CopyrightFooter />
      </div>
    </Router>
  );
}

export default App;
