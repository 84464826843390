import React from "react";
import "../styles/TernyxPage.css";

function TernyxPage() {
  return (
    <div className="ternyx-page">
      <section className="hero-section">
        <h1>Ternyx: Modular MatMul-Free AI Inference Cores</h1>
        <h3>The future of efficient AI silicon IP</h3>
      </section>

      <section className="overview-section">
        <h2>Revolutionizing Silicon IP for AI Inference</h2>
        <p>
          Ternyx is a modular, synthesizable RTL core library designed specifically for 
          silicon manufacturers who need to embed efficient AI inference capabilities 
          into their chips. Built for power-constrained, latency-critical, and cost-sensitive 
          deployments.
        </p>
      </section>

      <section className="features-section">
        <h2>Core Technology Benefits</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>MatMul-free Architecture</h3>
            <p>Replaces expensive multiplications with ternary accumulation and element-wise operations, reducing power consumption by up to 80%</p>
          </div>
          <div className="feature-card">
            <h3>Modular Design</h3>
            <p>Independent cores that can be composed or used separately, allowing flexible integration into your silicon designs</p>
          </div>
          <div className="feature-card">
            <h3>Optimized Quantization</h3>
            <p>Designed for BitNet, RWKV, MLGRU, or Transformer-lite models with minimal accuracy loss</p>
          </div>
          <div className="feature-card">
            <h3>Ultra-Low Power</h3>
            <p>Targets FPGAs, ASICs, and edge SoCs with minimal power requirements (&lt;10W, potentially &lt;1W)</p>
          </div>
        </div>
      </section>

      <section className="components-section">
        <h2>Silicon IP Components</h2>
        <div className="components-list">
          <div className="component">
            <h3>BitLinear</h3>
            <p>Ternary weight linear layer replacing dense matrix multiplication</p>
          </div>
          <div className="component">
            <h3>MLGRU</h3>
            <p>MatMul-free GRU for token mixing (attention replacement)</p>
          </div>
          <div className="component">
            <h3>GLU</h3>
            <p>Gated Linear Unit for channel mixing with SiLU activation</p>
          </div>
          <div className="component">
            <h3>RMSNorm</h3>
            <p>Efficient layer normalization</p>
          </div>
          <div className="component">
            <h3>Token FSM</h3>
            <p>Finite-state machine for autoregressive token generation</p>
          </div>
        </div>
      </section>

      <section className="business-model">
        <h2>Flexible Licensing Model</h2>
        <div className="licensing-options">
          <div className="option">
            <h3>Royalty-Based</h3>
            <p>Pay per chip with our scalable royalty structure</p>
          </div>
          <div className="option">
            <h3>One-Time License</h3>
            <p>Perpetual licensing options for high-volume manufacturers</p>
          </div>
          <div className="option">
            <h3>Custom Integration</h3>
            <p>Engineering support for optimizing Ternyx for your specific silicon</p>
          </div>
        </div>
      </section>

      <section className="performance-metrics">
        <h2>Performance Metrics</h2>
        <div className="metrics-table">
          <table>
            <thead>
              <tr>
                <th>Model Size</th>
                <th>Tokens per Second (TPS)</th>
                <th>Power Efficiency (mW/token)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>8 Billion Params</td>
                <td>~3,000 TPS</td>
                <td>~1.2 mW/token</td>
              </tr>
              <tr>
                <td>70 Billion Params</td>
                <td>~800 TPS</td>
                <td>~4.8 mW/token</td>
              </tr>
              <tr>
                <td>400 Billion Params</td>
                <td>~180 TPS</td>
                <td>~14.3 mW/token</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="integration-assets">
        <h2>Commercial Integration Assets</h2>
        <ul>
          <li>Synthesizable RTL cores in SystemVerilog/Verilog</li>
          <li>AXI bus interfaces for common SoC architectures</li>
          <li>Integrated memory controllers</li>
          <li>Example integration architectures</li>
          <li>Comprehensive documentation and integration guides</li>
          <li>Testbenches and verification suites</li>
        </ul>
      </section>

      <section className="cta-section"> 
        <h2>Ready to revolutionize your silicon?</h2>
        <p>Contact our silicon IP team to discuss licensing and integration opportunities.</p>
        <div className="cta-buttons">
          <a href="/contact" className="cta-button primary">Schedule a Consultation</a>
          <a href="mailto:contact@exla.ai" className="cta-button secondary">contact@exla.ai</a>
        </div>
      </section>
    </div>
  );
}

export default TernyxPage; 