import React from "react";

function HomePage() {
  return (
    <div style={{ fontSize: "15px" }}>
      <section id="one-liner">
        <h2>Data center models. Now on the edge.</h2>
      </section>

      <section id="section1">
        <li>LLMs, VLMs, VLAs, and CV models optimized for the edge</li>
        <li>3-20x faster inference, 2-5x smaller model size</li>
        <li>Pre-optimized models + support for custom models</li>
      </section>

      <section id="hardware-support">
        <h3>Supported Hardware</h3>
        <p>
          Edge: NVIDIA Jetson, Raspberry Pi
          <br />
          GPU: NVIDIA (consumer & datacenter)
          <br />
          CPU: Apple Silicon, Intel AVX-512, ARM NEON
          <br />
          Mobile: iPhone, Android device
        </p>
      </section>

      <section id="performance">
        <h3>Performance (Llama 3.1 8B) </h3>
        <p>
          NVIDIA Jetson: 160 tokens/s (4x faster)
          <br />
          NVIDIA H100: 27,000 tokens/s (6x faster)
          <br />
          iPhone 15 Pro: 24 tokens/s (3x faster)
          <br />
          Apple M3 Max: 55 tokens/s (3x faster)
        </p>
      </section>

      <section id="example-code">
        <div>
          <h3>Basic Usage</h3>
          <div>
            <pre>{`import exla

# Load your own model from Hugging Face, etc.
model = ...

# Hardware-specific optimization
optimized_model = exla.optimize(
    model,
    target="nvidia-jetson-orin-nano",
    memory_budget="8GB"       # optional memory constraint
)`}</pre>
          </div>
        </div>
      </section>

      <section id="custom-solutions">
        <h3>Custom Solutions</h3>
        <li>Need to deploy specific models?</li>
        <li>Have unique hardware requirements?</li>
        <li>Looking for custom optimizations?</li>
        <p>
          Contact us to discuss your use case at{" "}
          <a href="mailto:contact@exla.ai">contact@exla.ai</a>
        </p>
      </section>

      <section
        id="yc-logo"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ margin: 0, marginTop: 5, paddingRight: "10px" }}>
          Backed by
        </p>
        <img src="/yc-logo.png" height={25} width={25} alt="YC Logo" />
      </section>
    </div>
  );
}

function CopyrightFooter() {
  return (
    <>
      <footer id="footer">
        <hr />
        <p className="p-footer">
          &copy; 2025 Exla Corp. All rights reserved. contact@exla.ai
        </p>
      </footer>
    </>
  );
}

export { HomePage, CopyrightFooter };
